import "./src/styles/global.sass";
import "@fontsource-variable/lexend";
import "@fontsource-variable/inter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { trackEvent } from './src/utils/col';
import { pushDataLayer } from './src/utils/pushDataLayer';
import { auth } from "./src/utils/auth";

config.autoAddCss = false;

declare global {
    interface Window {
        Cookiebot: {
            consent: {
                marketing: boolean;
            };
        };
    }
}

let isInitialLoad = true

export function onRouteUpdate() {
    if (isInitialLoad) {
        isInitialLoad = false
    } else {
        trackEvent({
            eventType:'page_view'
        });
        pushDataLayer({
            event:'page_view'
        });
    }
};

export function onInitialClientRender() {
    if (typeof window !== "undefined") {
        auth();
        window.addEventListener("CookiebotOnAccept", () => {
            trackEvent({
                eventType:'page_view'
            });
            pushDataLayer({
                event:'page_view'
            });            
        });
    }
};