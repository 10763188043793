// Function to fetch a new token from the backend
export async function auth() {
    try {
        const response = await fetch('https://api.digipromotie.nl/v1/auth', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include', // Include credentials to handle cookies in cross-origin requests
        });
        
        if (response.ok) {
            const data = await response.json();
            return { token: data.tokenId, expiresInMinutes: data.tokenExpiresInMinutes };
        }
        throw new Error('Failed to fetch token');
    } catch (error) {
        console.error(error);
        return null;
    }
}
